document.addEventListener('DOMContentLoaded', () => {
  const carousel = document.querySelector('.owl-carousel');
  const items = carousel.querySelectorAll('.item');
  const itemCount = parseInt(carousel.dataset.items, 10);

  const hideNav = count => (items.length <= count ? 'hidden' : '');

  $(carousel).owlCarousel({
    navText: ['prev', 'next'],
    navClass: ['btn-backward text-transparent', 'btn-forward text-transparent'],
    stageClass: 'owl-stage flex',
    dots: false,
    margin: 0,
    items: itemCount,
    nav: true,
    responsive: {
      0: {
        items: 1,
        startPosition: 0,
        loop: items.length > 1,
        navContainerClass: `owl-nav mt-5 text-center ${() => hideNav(1)}`,
      },
      768: {
        items: 2,
        loop: items.length > 2,
        navContainerClass: `owl-nav mt-5 text-center ${() => hideNav(2)}`,
      },
      1024: {
        items: itemCount,
        loop: items.length > itemCount,
        navContainerClass: `owl-nav mt-5 text-center ${() => hideNav(itemCount)}`,
      },
    },
  });
});
